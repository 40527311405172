import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const originalPush = Router.prototype.push
// 重写了原型上的push方法，统一的处理了错误信息
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: [
    {
		path: '/',
		name: 'index',
		component: () => import('@/page/index/index'),
		meta: {title: 'Megacash'}
    },
	{
	  path: '/login',
	  component: () => import('@/page/login/login'),
	  routeDetail:'/login',
	  meta: { title: 'login'},
	},
	{
	  path: '/register',
	  component: () => import('@/page/login/register'),
	  routeDetail:'/register',
	  meta: { title: 'register'}
	},
	{
	  path: '/smsLogin',
	  component: () => import('@/page/login/code'),
	  routeDetail:'/smsLogin',
	  meta: { title: 'smsLogin'}
	},
	{
	  path: '/pwdLogin',
	  component: () => import('@/page/login/pwd'),
	  routeDetail:'pwdLogin',
	  meta: { title: 'pwdLogin'}
	},
	  {
	  path: '/user',
	  component: () => import('@/components/layout'),
	  redirect: '/user/userCenter',
	  name:'user',
	  meta: { title: 'User'},
	  children:[
		  {
			  path: 'userCenter',
			  component: () => import('@/page/user/userCenter'),
			  name: 'userCenter',
			  routeDetail:'user/userCenter',
			  meta: { title: 'UserCenter'},
		  },
		  {
			  path: 'repaymentList',
			  component: () => import('@/page/user/repaymentList'),
			  routeDetail:'user/repaymentList',
			  meta: { title: 'RepaymentHistory'}
		  },
		  {
			  path: 'BankCardList',
			  component: () => import('@/page/user/BankCardList'),
			  routeDetail:'user/BankCardList',
			  meta: { title: 'BankCard'}
		  },
		  {
			  path: 'LoanHistory',
			  component: () => import('@/page/user/LoanHistory'),
			  routeDetail:'user/LoanHistory',
			  meta: { title: ' LoanHistory '}
		  },
		  {
			  path: 'notification',
			  component: () => import('@/page/user/notification'),
			  routeDetail:'user/notification',
			  meta: { title: 'notification'}
		  },
		  {
			  path: 'changePassword',
			  component: () => import('@/page/login/changePassword'),
			  routeDetail:'changePassword',
			  meta: { title: 'changePassword'}
		  },
	  ]
	},
  {
	  path: '/step1',
	  component: () => import('@/page/step/step1'),
	  routeDetail:'step1',
	  meta: { title: 'PersonalInformation'}
  },
  {
	  path: '/step2',
	  component: () => import('@/page/step/step2'),
	  routeDetail:'step2',
	  meta: { title: 'EmergencyContacts'}
  },
  {
	  path: '/step3',
	  component: () => import('@/page/step/step3'),
	  routeDetail:'step3',
	  meta: { title: 'BankInformation'}
  },
  {
	  path: '/step4',
	  component: () => import('@/page/step/step4'),
	  routeDetail:'step4',
	  meta: { title: 'IdentityInformation'}
  },
  {
	  path: '/loanProduct',
	  component: () => import('@/page/loan/loanProduct'),
	  routeDetail:'loanProduct',
	  meta: { title: 'Applyloan'}
  },
  {
	  path: '/result',
	  component: () => import('@/page/loan/result'),
	  routeDetail:'result',
	  meta: { title: ' Result '}
  },
  {
	  path: '/repayment',
	  component: () => import('@/page/loan/repayment'),
	  routeDetail:'repayment',
	  meta: { title: ' repayment'}
  },
  {
	  path: '/repayProof',
	  component: () => import('@/page/loan/repayProof'),
	  routeDetail:'repayProof',
	  meta: { title: 'UploadVoucher'}
  },


  {
	  path: '/bankRollback',
	  component: () => import('@/page/loan/bankRollback'),
	  routeDetail:'bankRollback',
	  meta: { title: 'bankRollback'}
  },
  {
	  path: '/photoRollback',
	  component: () => import('@/page/loan/photoRollback'),
	  routeDetail:'photoRollback',
	  meta: { title: 'photoRollback'}
  },
  {
	  path: '/HowToBorrow',
	  component: () => import('@/page/index/HowToBorrow'),
	  routeDetail:'HowToBorrow',
	  meta: { title: 'howToBorrow'}
  },
  {
	  path: '/HowToRepay',
	  component: () => import('@/page/index/HowToRepay'),
	  routeDetail:'HowToRepay',
	  meta: { title: 'howToRepay'}
  },
  {
	  path: '/FAQ',
	  component: () => import('@/page/index/FAQ'),
	  routeDetail:'FAQ',
	  meta: { title: 'faq'}
  },
  {
	  path: '/About',
	  component: () => import('@/page/index/About'),
	  name: 'About',
	  routeDetail:'About',
	  meta: { title: 'about'}
  },
  {
	  path: '/Contact',
	  component: () => import('@/page/index/Contact'),
	  name: 'Contact',
	  routeDetail:'Contact',
	  meta: { title: 'contact'}
  },
  ]
})
